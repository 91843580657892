class ShaderLayer extends Layer
	webgl : true
	current : 0
	map : []
	flip : () ->
		@current = 1 - @current
	pos_to_index : (pos) ->
		x : floor((pos.x / @size) * @res)
		y : floor((pos.y / @size) * @res)
	index_to_pixel : (index, channel = 0) ->
		(index.x + (@res - index.y - 1) * @res) * 4 + channel
	bipolar_byte : (byte) -> (@unipolar_byte(byte) - 0.5) * 2.0
	unipolar_byte : (byte) -> byte / 255
	get_pixel : (pos, channel = 0) ->
		if @map.length is 0
			# console.error "map isn't initialized"
		else
			@map[@index_to_pixel @pos_to_index(pos), channel]